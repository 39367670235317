<template>
  <v-container style="padding: 0;">
    <v-btn :disabled="loading || !selectedBudgets.length" color="success" depressed size="small" @click="getXML"
      :loading="loading">
      <v-icon left>mdi-file-xml</v-icon> Gerar XML
    </v-btn>
  </v-container>
</template>

<script>
import api from "../../../http";
import moment from "moment";
import { create } from 'xmlbuilder2';
import UtilsFunc from "../../../service/utilsFunc";
import UtilsPDF from '../../../service/utilsPDF';
import md5 from 'md5';

const { withCRUDUtils } = UtilsFunc

// funçõa para trocar acentos por item sem acentuação e remover caracter especial
function cleanText(input) {
  const accentsMap = {
    'á': 'a', 'Á': 'A', 'à': 'a', 'À': 'A', 'ã': 'a', 'Ã': 'A', 'â': 'a', 'Â': 'A',
    'é': 'e', 'É': 'E', 'è': 'e', 'È': 'E', 'ê': 'e', 'Ê': 'E',
    'í': 'i', 'Í': 'I', 'ì': 'i', 'Ì': 'I', 'î': 'i', 'Î': 'I',
    'ó': 'o', 'Ó': 'O', 'ò': 'o', 'Ò': 'O', 'õ': 'o', 'Õ': 'O', 'ô': 'o', 'Ô': 'O',
    'ú': 'u', 'Ú': 'U', 'ù': 'u', 'Ù': 'U', 'û': 'u', 'Û': 'U',
    'ç': 'c', 'Ç': 'C',
    'ü': 'u', 'Ü': 'U'
  };
  let output = input.split('').map(char => accentsMap[char] || char).join('');
  return output.replace(/[^a-zA-Z0-9\s]/g, ''); 
}
function formatCurrency(value) {
  return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
}

export default {
  name: "XML",
  props: {
    selectedBudgets: {
      type: Array,
      default: () => []
    },
  },
  data: () => withCRUDUtils({
    empresa: [],
    procedimentos: [],
    equipamentos: [],
    procedimentos2: [],
    taxas: [],
    pacotes: [],
    combinedData: [],
    loading: false,
  }),
  methods: {
    async fetchAllData() {
      this.loading = true;
      try {
        await Promise.all([
          this.getOrcamento(),
          this.getProcedimento(),
          this.getEquipamentos(),
          this.getTaxa(),
          this.getPacote(),
        ]);

        // Combine os dados em combinedData
        this.combinedData = [
          ...this.procedimentos2,
          ...this.equipamentos,
          ...this.taxas,
          ...this.pacotes,
        ];

        // console.log('Combined Data:', this.combinedData);
        this.loading = false;
      } catch (error) {
        console.error("Erro ao carregar os dados:", error);
        this.$toast.error("Erro ao carregar os dados: " + error.message);
        this.loading = false;
      }
    },
    async getXML() {
      this.loading = true;
      this.$toast.success(
        `O documento está sendo preparado. Isso pode levar alguns instantes. Aguarde, por favor.`
      );
      try {
        await this.fetchAllData();

        if (this.tipoGuiaUnico.size > 1) {
          this.$toast.error("Os tipos de guia dos procedimentos devem ser os mesmos para gerar o XML.");
          return
        } else {
          const valorTotalGeral = this.generateXML(); // Chamar generateXML e armazenar o valor retornado
          const valorFormatado = formatCurrency(valorTotalGeral); // Formatar o valor
          this.$toast.success(`O download do XML foi realizado com sucesso! Valor Total: ${valorFormatado}`);
        }
        this.loading = false;
      } catch (error) {
        console.error("Erro ao carregar os dados:", error);
        this.$toast.error("Erro ao carregar os dados: " + error.message);
        this.loading = false;
      }
    },
    generateXML() {
      const empresa = this.empresa[0];
      const selectedGuides = this.selectedBudgets;
      const totalValuePerBudget = {};
      let valorDiarias = 0;
      let valorProcedimentos = 0;
      let valorTaxasAlugueis = 0;

      for (const item of this.procedimentos.flat()) {
        if (!selectedGuides.includes(item?.sessao.id)) continue;
        valorProcedimentos += item?.valor_cobranca_final;
      }

      for (const item of this.combinedData.flat()) {
        if (!selectedGuides.includes(item?.sessao.id)) continue;
        if ([43, 44, 45, 46].includes(item?.procedimento?.procedimento_id)) {
            valorDiarias +=  item?.valor_cobranca_final;
        } else {
            valorTaxasAlugueis += ( (item?.valor_cobranca_final_taxa || 0) + (item?.valor_cobranca_final_equipamento || 0) + (item?.valor_cobranca_final_pacote || 0));
        }
      }

      // Calcular valorTaxasAlugueis para procedimentos2
      for (const item of this.procedimentos2.flat()) {
        if (!selectedGuides.includes(item?.sessao.id) || [43, 44, 45, 46].includes(item?.procedimento?.procedimento_id)) continue;
        valorTaxasAlugueis += (item?.valor_cobranca_final || 0) + (item?.valor_cobranca_final_taxa || 0) + (item?.valor_cobranca_final_equipamento || 0) + (item?.valor_cobranca_final_pacote || 0) ;
      }

      let valorTotalGeral = valorDiarias + valorProcedimentos + valorTaxasAlugueis;

      let procedimentos = [];
      let sequencialItem = 1;
      let nonSpecialProcedures = [];
      let specialProcedures = [];
      let despesasProcedimentos2 = [];
      
      for (const item of this.procedimentos.flat()) {
        if (!selectedGuides.includes(item?.sessao.id)) {
          continue;
        }
        
        let desconto = item?.desconto    
        let acrescimo = item?.acrescimo 
        let percentual = desconto ? - (desconto) : acrescimo 

        if (!totalValuePerBudget[item?.sessao.id]) {
          totalValuePerBudget[item?.sessao.id] = [];
        }
        totalValuePerBudget[item?.sessao.id].push(parseFloat(item?.valor_cobranca_final.toFixed(2)));
        const nomeProcedimento = (item?.procedimento?.nomecomercial  && item.procedimento.nomecomercial.trim() !== "") ? item?.procedimento?.nomecomercial : item?.procedimento?.procedimento; 

        if (!item?.procedimento.especial) {
          nonSpecialProcedures.push({
            'ans:sequencialItem': sequencialItem++,
            'ans:dataExecucao': item?.timestamp ? UtilsPDF.formatDateXML(item?.timestamp) : item?.data_especial,
            'ans:horaInicial': item?.hora_inicio ? item?.hora_inicio : '00:00:00',
            'ans:horaFinal': item?.hora_fim ? item?.hora_fim : '23:59:59',
            'ans:procedimento': {
              'ans:codigoTabela': item?.procedimento.tabela_tabela,
              'ans:codigoProcedimento': item?.procedimento.codigo,
              'ans:descricaoProcedimento':  nomeProcedimento,
            },
            'ans:quantidadeExecutada': item?.quantidade,
            'ans:reducaoAcrescimo': percentual ? percentual.toFixed(1) : 1,
            'ans:valorUnitario': item?.valor_cobranca.toFixed(2),
            'ans:valorTotal': item?.valor_cobranca_final.toFixed(2),
          });
        }
      }

      procedimentos = nonSpecialProcedures.concat(specialProcedures);

      let sequencialItemProcedimentos2 = 1;
      // Processando procedimentos2
      for (const item of this.combinedData.flat()) {
        if (!selectedGuides.includes(item?.sessao?.id)) {
          continue;
        }
        let desconto = item?.desconto  || item?.desconto_equipamento || item?.desconto_taxa || item?.desconto_pacote    
        let acrescimo = item?.acrescimo  || item?.acrescimo_equipamento || item?.acrescimo_taxa || item?.acrescimo_pacote
        let percentual = desconto ? -(desconto) : acrescimo 

        let valorCobranca = (item?.valor_cobranca  || item?.valor_cobranca_equipamento || item?.valor_cobranca_taxa || item?.valor_cobranca_pacote)
        let valorCobrancaFinal = (item?.valor_cobranca_final  || item?.valor_cobranca_final_equipamento || item?.valor_cobranca_final_taxa || item?.valor_cobranca_final_pacote)

        if (!totalValuePerBudget[item?.sessao.id]) {
          totalValuePerBudget[item?.sessao.id] = [];
        }
        totalValuePerBudget[item?.sessao.id].push(parseFloat(valorCobrancaFinal.toFixed(2)));
       
        if ((item?.procedimento?.xmlagrupado || item?.equipamento?.xmlagrupado?.id || item?.taxa?.xmlagrupado?.id || item?.pacote?.xmlagrupado?.id) === 2) {
          let codigoDespesa = (item?.procedimento?.despesa?.despesa || item?.equipamento?.despesa?.despesa || item?.taxa?.despesa?.despesa || item?.pacote?.despesa?.despesa)
          let dataExecucaoUnica = moment(item?.data_inicio_especial || item?.data_inicio_equipamento || item?.data_inicio_taxa || item?.data_inicio_pacote).format('YYYY-MM-DD');
          let dataExecucao = dataExecucaoUnica;
          let quantidadeTodos = (item?.quantidade  || item?.quantidade_equipamento || item?.quantidade_taxa || item?.quantidade_pacote)
          let valorTabela = (item?.procedimento?.tabela_tabela || item?.equipamento?.tabela?.tabela || item?.taxa?.tabela?.tabela || item?.pacote?.tabela?.tabela);
          let valorCodigo = (item?.procedimento?.codigo || item?.equipamento?.codigo || item?.taxa?.codigo || item?.pacote?.codigo);
          let unidadedeMedida = (item?.procedimento?.unidade_medida || item?.equipamento?.unidade_medida || item?.taxa?.unidade_medida || item?.pacote?.unidade_medida?.codigo)
          let nomeCompletoProcedimento = (item?.procedimento?.nomecomercial  && item.procedimento.nomecomercial.trim() !== "") ? item?.procedimento?.nomecomercial : item?.procedimento?.procedimento;
          let nomeCompetoEquipamento =  (item?.equipamento?.nomecomercial  && item.equipamento.nomecomercial.trim() !== "") ? item?.equipamento?.nomecomercial : item?.equipamento?.equipamento?.nome;
          let nomeCompetoTaxa =  (item?.taxa?.nomecomercial  && item.taxa.nomecomercial.trim() !== "") !== null ? item?.taxa?.nomecomercial : item?.taxa?.taxa?.nome;
          let nomeCompetoPacote =  (item?.pacote?.nomecomercial  && item.pacote.nomecomercial.trim() !== "") !== null ? item?.pacote?.nomecomercial : item?.pacote?.nome;
          let nomeProcedimento = (nomeCompletoProcedimento  || nomeCompetoEquipamento || nomeCompetoTaxa || nomeCompetoPacote)

          for (let i = 0; i < quantidadeTodos; i++) {
            despesasProcedimentos2.push({
              'ans:sequencialItem': sequencialItemProcedimentos2++,
              'ans:codigoDespesa': codigoDespesa,
              'ans:servicosExecutados': {
                'ans:dataExecucao': item?.timestamp ? UtilsPDF.formatDateXML(item?.timestamp) : UtilsPDF.formatDateXML(dataExecucao),
                'ans:codigoTabela': valorTabela,
                'ans:codigoProcedimento': valorCodigo,
                'ans:quantidadeExecutada': 1,
                'ans:unidadeMedida':  unidadedeMedida,
                'ans:reducaoAcrescimo': percentual ? percentual.toFixed(1) : 1,
                'ans:valorUnitario': valorCobranca.toFixed(2),
                'ans:valorTotal': (valorCobrancaFinal / quantidadeTodos).toFixed(2),
                'ans:descricaoProcedimento': cleanText(nomeProcedimento),
              },
            });
            dataExecucao = moment(dataExecucao).add(1, 'days').toDate();
          }
        } else {
          let codigoDespesa = (item?.procedimento?.despesa?.despesa || item?.equipamento?.despesa?.despesa || item?.taxa?.despesa?.despesa || item?.pacote?.despesa?.despesa)
          let dataExecucaoUnica = moment(item?.data_inicio_especial || item?.data_inicio_equipamento || item?.data_inicio_taxa || item?.data_inicio_pacote).format('YYYY-MM-DD');
          let dataExecucao = dataExecucaoUnica;
          let quantidadeTodos = (item?.quantidade  || item?.quantidade_equipamento || item?.quantidade_taxa || item?.quantidade_pacote)
          let valorTabela = (item?.procedimento?.tabela_tabela || item?.equipamento?.tabela?.tabela || item?.taxa?.tabela?.tabela || item?.pacote?.tabela?.tabela);
          let valorCodigo = (item?.procedimento?.codigo || item?.equipamento?.codigo || item?.taxa?.codigo || item?.pacote?.codigo);
          let unidadedeMedida = (item?.procedimento?.unidade_medida || item?.equipamento?.unidade_medida || item?.taxa?.unidade_medida || item?.pacote?.unidade_medida?.codigo)
          let valorcobrancaindividual = ((item?.valor_cobranca ||  item?.valor_cobranca_equipamento || item?.valor_cobranca_taxa || item?.valor_cobranca_pacote) || 0 )
          let valorCobrancaTodos = ( (item?.valor_cobranca_final || item?.valor_cobranca_final_equipamento || item?.valor_cobranca_final_taxa || item?.valor_final_cobranca_pacote) || 0 ) 
           let nomeCompletoProcedimento = (item?.procedimento?.nomecomercial  && item.procedimento.nomecomercial.trim() !== "") ? item?.procedimento?.nomecomercial : item?.procedimento?.procedimento;
          let nomeCompetoEquipamento =  (item?.equipamento?.nomecomercial  && item.equipamento.nomecomercial.trim() !== "") ? item?.equipamento?.nomecomercial : item?.equipamento?.equipamento?.nome;
          let nomeCompetoTaxa =  (item?.taxa?.nomecomercial  && item.taxa.nomecomercial.trim() !== "") !== null ? item?.taxa?.nomecomercial : item?.taxa?.taxa?.nome;
          let nomeCompetoPacote =  (item?.pacote?.nomecomercial  && item.pacote.nomecomercial.trim() !== "") !== null ? item?.pacote?.nomecomercial : item?.pacote?.nome;
          let nomeProcedimento = (nomeCompletoProcedimento  || nomeCompetoEquipamento || nomeCompetoTaxa || nomeCompetoPacote)

          despesasProcedimentos2.push({
            'ans:sequencialItem': sequencialItemProcedimentos2++,
            'ans:codigoDespesa': codigoDespesa,
            'ans:servicosExecutados': {
              'ans:dataExecucao': item?.timestamp ? UtilsPDF.formatDateXML(item?.timestamp) : UtilsPDF.formatDateXML(dataExecucao),
              'ans:codigoTabela': valorTabela,
              'ans:codigoProcedimento': valorCodigo,
              'ans:quantidadeExecutada': quantidadeTodos,
              'ans:unidadeMedida': unidadedeMedida,
              'ans:reducaoAcrescimo': percentual ? percentual.toFixed(1) : 1,
              'ans:valorUnitario': valorcobrancaindividual.toFixed(2),
              'ans:valorTotal': valorCobrancaTodos.toFixed(2),
              'ans:descricaoProcedimento': cleanText(nomeProcedimento),
            }
          });
        }
      }

      const valorTotalPorSessaoGeral = {};
      for (const sessaoId in totalValuePerBudget) {
        valorTotalPorSessaoGeral[sessaoId] = totalValuePerBudget[sessaoId].reduce((acc, cur) => acc + cur, 0);
      }
      
      const menorNumero = Math.min(...selectedGuides);
      const dataAtual = UtilsPDF.formatDateXML(new Date());
      const dataNumerica = parseInt(dataAtual.replace(/\D/g, ''));
      const sequencialTransacao = dataNumerica + menorNumero;

      var obj = {
        'ans:mensagemTISS': {
          '@xsi:schemaLocation': 'http://www.ans.gov.br/padroes/tiss/schemas http://www.ans.gov.br/padroes/tiss/schemas/tissV4_01_00.xsd',
          '@xmlns:ans': 'http://www.ans.gov.br/padroes/tiss/schemas',
          '@xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
          'ans:cabecalho': {
            'ans:identificacaoTransacao': {
              'ans:tipoTransacao': 'ENVIO_LOTE_GUIAS',
              'ans:sequencialTransacao': sequencialTransacao,
              'ans:dataRegistroTransacao': dataAtual,
              'ans:horaRegistroTransacao': moment().format('HH:mm:ss')
            }
            , 'ans:origem': {
              'ans:identificacaoPrestador': {
                'ans:codigoPrestadorNaOperadora': empresa.convenio_codigo ?? 'Não informado'
              }
            }
            , 'ans:destino': {
              'ans:registroANS': empresa.convenio_ans
            }
            , 'ans:Padrao': empresa.versao_xml
          },
          'ans:prestadorParaOperadora': {
            'ans:loteGuias': {
              'ans:numeroLote': empresa.id ?? 'Não informado',
              'ans:guiasTISS': {
                [`${this.tipoGuia === 3 ? 'ans:guiaSP-SADT' : 'ans:guiaResumoInternacao'}`]: selectedGuides.map(id => {
                  const array = this.procedimentos.flat().filter(item => item?.sessao.id === id);
                  return {
                    'ans:cabecalhoGuia': {
                      'ans:registroANS': empresa.convenio_ans,
                      'ans:numeroGuiaPrestador': empresa.nr_guia_prestador
                    },
                    ...(array[0]?.procedimento.tipoguia != 3  && {
                      'ans:numeroGuiaSolicitacaoInternacao': empresa.nr_guia_prestador,
                    }),
                    'ans:dadosAutorizacao': {
                      'ans:numeroGuiaOperadora': empresa.nr_guia_operador,
                      'ans:dataAutorizacao': empresa.senha_data_inicio,
                      'ans:senha': empresa.senha,
                      'ans:dataValidadeSenha': empresa.senha_data_validade
                    },
                    'ans:dadosBeneficiario': {
                      'ans:numeroCarteira':  empresa.nr_carteirinha,
                      'ans:atendimentoRN': empresa.atendimentoRN === false ? 'N' : 'S',
                    },
                    ...(array[0]?.procedimento.tipoguia === 3 && {
                      'ans:dadosSolicitante': {
                        'ans:contratadoSolicitante': {
                          'ans:codigoPrestadorNaOperadora': empresa.convenio_codigo,
                        },
                        'ans:nomeContratadoSolicitante': cleanText(empresa.convenio_codigo),
                        'ans:profissionalSolicitante': {
                          'ans:nomeProfissional': cleanText(array[0]?.medico?.first_name) ?? 'Informação incompleta',
                          'ans:conselhoProfissional': array[0]?.medico?.conselho_codigo,
                          'ans:numeroConselhoProfissional': array[0]?.medico?.nconselho,
                          'ans:UF': array[0]?.medico?.uf_inscricao_conselho_codigo,
                          'ans:CBOS': array[0]?.medico?.especialidade_cbo
                        }
                      },
                      'ans:dadosSolicitacao': {
                        'ans:caraterAtendimento': empresa.tipo_carater_atendimento,
                        'ans:indicacaoClinica': empresa.modalidade
                      },
                    }),
                    'ans:dadosExecutante': {
                      'ans:contratadoExecutante': {
                        'ans:codigoPrestadorNaOperadora': empresa.convenio_codigo,
                      },
                      'ans:CNES': empresa.empresa_cnes,
                    },
                    ...(array[0]?.procedimento.tipoguia === 3 && {
                      'ans:dadosAtendimento': {
                        'ans:tipoAtendimento':empresa.tipoatendimento_codigo,
                        'ans:indicacaoAcidente': empresa.indicacaoacideente_codigo,
                        'ans:regimeAtendimento': empresa.regimeatendimento_codigo
                      },
                    }),
                    ...(array[0]?.procedimento.tipoguia != 3 && {
                      'ans:dadosInternacao': {
                        'ans:caraterAtendimento': empresa.tipo_carater_atendimento,
                        'ans:tipoFaturamento': empresa.tipo_faturamento,
                        'ans:dataInicioFaturamento': empresa.data_inicio,
                        'ans:horaInicioFaturamento': "00:00:00",
                        'ans:dataFinalFaturamento': empresa.data_fim,
                        'ans:horaFinalFaturamento': "23:59:59",
                        'ans:tipoInternacao': empresa.tipo_internacao,
                        'ans:regimeInternacao': empresa.tipo_regime_internacao ,
                      },
                      'ans:dadosSaidaInternacao': {
                        'ans:diagnostico': empresa.cid,
                        'ans:indicadorAcidente': empresa.indicacaoacideente_codigo,
                        'ans:motivoEncerramento': empresa.tipo_motivo_encerramento,
                      },
                    }),
                    ...(array[0]?.procedimento.tipoguia === 3 && {
                    'ans:procedimentosExecutados': {
                      'ans:procedimentoExecutado': procedimentos
                    },
                    }),
                    ...(array[0]?.procedimento.tipoguia !== 3 && {
                      'ans:valorTotal': {
                        ...(valorProcedimentos > 0 && {'ans:valorProcedimentos': valorProcedimentos.toFixed(2)}),
                        ...(valorDiarias > 0 && {'ans:valorDiarias': valorDiarias.toFixed(2)}),
                        ...(valorTaxasAlugueis > 0 && {'ans:valorTaxasAlugueis': valorTaxasAlugueis.toFixed(2)}),
                        ...(valorTotalGeral > 0 && {'ans:valorTotalGeral': valorTotalGeral.toFixed(2)}),
                      },
                    }),
                    ...(this.combinedData.length > 0 && {
                      'ans:outrasDespesas': {
                        'ans:despesa': despesasProcedimentos2
                      }
                    }),
                    ...(array[0]?.procedimento.tipoguia === 3 && {
                      'ans:valorTotal': {
                        ...(valorProcedimentos > 0 && {'ans:valorProcedimentos': valorProcedimentos.toFixed(2)}),
                        ...(valorDiarias > 0 && {'ans:valorDiarias': valorDiarias.toFixed(2)}),
                        ...(valorTaxasAlugueis > 0 && {'ans:valorTaxasAlugueis': valorTaxasAlugueis.toFixed(2)}),
                        ...(valorTotalGeral > 0 && {'ans:valorTotalGeral': valorTotalGeral.toFixed(2)}),
                      },
                    }),
                  }
                })
              }
            }
          }
        }
      }
      const root = create(obj)
      const xml = root.end({ prettyPrint: true, headless: true });

      const inicioCabecalho = xml.indexOf("<ans:cabecalho>");
      const fimCabecalho = xml.indexOf("</ans:cabecalho>");
      const cabecalho = xml.substring(inicioCabecalho, fimCabecalho + "</ans:cabecalho>".length);

      const inicioPrestador = xml.indexOf("<ans:prestadorParaOperadora>");
      const fimPrestador = xml.indexOf("</ans:prestadorParaOperadora>");
      const prestadorParaOperadora = xml.substring(inicioPrestador, fimPrestador + "</ans:prestadorParaOperadora>".length);

      const conteudoParaHash = cabecalho + prestadorParaOperadora;
      let conteudoTexto = conteudoParaHash.replace(/\n/g, '').replace(/>\s+</g, '').replace(/<[^>]+>/g, '').trim();

      const hash = md5(conteudoTexto);
      var epilogo = { 'ans:epilogo': { 'ans:hash': hash } };
      obj['ans:mensagemTISS']['ans:epilogo'] = epilogo['ans:epilogo'];
      const rootWithEpilogue = create(obj)
      const xmlWithEpilogue = rootWithEpilogue.end({ prettyPrint: true, headless: true });
      const xmlWithEncoding = `<?xml version="1.0" encoding="iso-8859-1"?>\n${xmlWithEpilogue}`;
      const nomePaciente = cleanText(empresa.paciente.replace(/\s/g, "_"))
      const nomeConvenio = cleanText(empresa.convenio.replace(/\s/g, "_"))
      const now = new Date()
      this.selectedBudgets.length > 1 ?
        this.downloadXML(xmlWithEncoding, `XML_${nomeConvenio}_${this.formatDateNormal(now)}_Conta_${empresa.id}.xml`)
        : this.downloadXML(xmlWithEncoding, `XML_${nomePaciente}_${nomeConvenio}_${this.formatDateNormal(empresa.data_inicio)}_ate_${this.formatDateNormal(empresa.data_fim)}_Conta_${empresa.id}.xml`)
   
      this.updateXmlGerado();
        
      return valorTotalGeral;
      },
     async updateXmlGerado() {
      const dados = this.empresa[0]
        const fields = {
          paciente: Number(dados.paciente_id),
          xml_gerado: true
        };
        try {
          await api.put(`atendimentos/sessoes_put_xml/${dados.id}/`, fields);
        } catch (error) {
          console.error('Erro ao atualizar o campo xml_gerado:', error);
          this.$toast.error('Erro ao atualizar o campo xml_gerado.');
        }
      },
    downloadXML(xml, filename) {
      try {
        // Cria um Blob com os dados XML
        const blob = new Blob([xml], { type: 'application/xml' });

        // Cria um URL para o Blob
        const url = window.URL.createObjectURL(blob);

        // Cria um elemento de link temporário
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = filename;

        // Adiciona o link ao documento e dispara o download
        document.body.appendChild(a);
        a.click();

        // Limpa e remove o link após o download
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        // Mensagem de sucesso
        // this.$toast.success('O download do XML foi realizado com sucesso!');
      } catch (error) {
        console.error('Erro ao salvar o arquivo:', error);
        this.$toast.error("Erro ao salvar o arquivo: " + error.message);
      }
    },
    formatDateNormal(value) {
      if (!value) return "";
      return moment(value).format("DD_MM_YYYY");
    },
    async getOrcamento() {
      if (this.selectedBudgets.length > 0) {
        try {
          const requests = this.selectedBudgets.map(id => api.get(`atendimentos/relatorio-orcamento/?orcamento=${id}`));
          const responses = await Promise.all(requests);
          const data = responses.map(response => response.data);
          // console.log('getOrcamento --> ', data);
          this.empresa = data[0];
        } catch (error) {
          console.error('Erro ao buscar os dados do orçamento:', error);
        }
      }
    },
    async getProcedimento() {
      this.procedimentos = [];
      this.procedimentos2 = [];

      if (this.selectedBudgets.length > 0) {
        try {
          const procedimentosPromises = this.selectedBudgets.map(async (orcamentoId) => {
            const response = await api.get(`atendimentos/evolucoesxml/?sessao=${orcamentoId}`);
            const data = response.data.filter(item =>
              item?.ativo === true &&
              item?.cobranca === true &&
              (item?.assinatura !== null || item?.procedimento.especial === true) &&
              item?.valor_cobranca !== null &&
              item?.desconto !== 100 &&
              item?.valor_cobranca !== 0
            );

            // Filtrar e separar os procedimentos
            const procedimentos = data.filter(item => item?.procedimento.tipoguia !== 5);
            const procedimentos2 = data.filter(item => item?.procedimento.tipoguia === 5);

            return { procedimentos, procedimentos2 };
          });

          const resultados = await Promise.all(procedimentosPromises);
          
          // Mesclar os resultados em suas respectivas listas
          this.procedimentos = resultados.flatMap(result => result.procedimentos);
          this.procedimentos2 = resultados.flatMap(result => result.procedimentos2);
          // console.log('Procedimento2', this.procedimentos2)
          // Verificar se todos os tipos de guia são iguais
          this.tipoGuiaUnico = new Set(this.procedimentos.map(item => item?.procedimento.tipoguia));
          const [value] = [...this.tipoGuiaUnico];

          this.tipoGuia = value;

          // console.log('Tipo da guia -->', value);
          // console.log('getProcedimento -->', this.procedimentos);
          // console.log('getProcedimento2 -->', this.procedimentos2);
        } catch (error) {
          console.error('Erro ao buscar os dados dos procedimentos:', error);
        }
      }
    },
    async getTaxa() {
      if (this.selectedBudgets.length > 0) {
        try {
          const { data } = await api.get(`atendimentos/taxas/?orcamento=${this.selectedBudgets}`);
          this.taxas = data.filter(item => item?.ativo && item.desconto_taxa !== 100);
          // console.log('Taxas --> ', this.taxas)
        } catch (error) {
          console.error('Erro ao buscar os dados da taxa:', error);
        }
      }
    },
    async getEquipamentos() {
      if (this.selectedBudgets.length > 0) {
        try {
          const { data } = await api.get(`atendimentos/equipamentos/?orcamento=${this.selectedBudgets}`);
          this.equipamentos = data.filter(item => item?.ativo && item.desconto_equipamento !== 100);
          // console.log('Equipamentos --> ', this.equipamentos)
        } catch (error) {
          console.error('Erro ao buscar os dados do euipamento:', error);
        }
      }
    },
    async getPacote() {
      if (this.selectedBudgets.length > 0) {
        try {
          const { data } = await api.get(`atendimentos/pacotes/?orcamento=${this.selectedBudgets}`);
          this.pacotes = data.filter(item => item?.ativo && item.desconto_pacote !== 100 && item.valor_cobranca_final_pacote != 0 && item.valor_cobranca_final_pacote != null )
          // console.log('Pacote --> ', this.pacotes)
        } catch (error) {
          console.error('Erro ao buscar os dados do pacote:', error);
        }
      }
    },
  },
};
</script>