<template>
  <v-container style="padding: 0;">
    <v-btn :disabled="loading || !selectedBudgets.length" color="success" depressed size="small" @click="getXML"
      :loading="loading">
      <v-icon left>mdi-file-xml</v-icon> Gerar XML (GLOSA)
    </v-btn>
  </v-container>
</template>

<script>
import api from "../../../http";
import moment from "moment";
import { create } from 'xmlbuilder2';
import UtilsFunc from "../../../service/utilsFunc";
import UtilsPDF from '../../../service/utilsPDF';
import md5 from 'md5';

const { withCRUDUtils } = UtilsFunc

// funçõa para trocar acentos por item sem acentuação e remover caracter especial
function cleanText(input) {
  const accentsMap = {
    'á': 'a', 'Á': 'A', 'à': 'a', 'À': 'A', 'ã': 'a', 'Ã': 'A', 'â': 'a', 'Â': 'A',
    'é': 'e', 'É': 'E', 'è': 'e', 'È': 'E', 'ê': 'e', 'Ê': 'E',
    'í': 'i', 'Í': 'I', 'ì': 'i', 'Ì': 'I', 'î': 'i', 'Î': 'I',
    'ó': 'o', 'Ó': 'O', 'ò': 'o', 'Ò': 'O', 'õ': 'o', 'Õ': 'O', 'ô': 'o', 'Ô': 'O',
    'ú': 'u', 'Ú': 'U', 'ù': 'u', 'Ù': 'U', 'û': 'u', 'Û': 'U',
    'ç': 'c', 'Ç': 'C',
    'ü': 'u', 'Ü': 'U'
  };
  let output = input.split('').map(char => accentsMap[char] || char).join('');
  return output.replace(/[^a-zA-Z0-9\s]/g, ''); 
}


export default {
  name: "Produtividade",
  props: {
    selectedBudgets: {
      type: Array,
      default: () => []
    },
  },
  data: () => withCRUDUtils({
    empresa: [],
    procedimentos: [],
    loading: false,
    codigorecurso: [],
  }),
  methods: {
    async getXML() {
      this.loading = true;
      this.$toast.success(
        `O documento está sendo preparado. Isso pode levar alguns instantes. Aguarde, por favor.`
      );
      try {
        await Promise.all([
          this.getOrcamento(),
          this.getProcedimento(),
          this.getPacote(),
          this.getTaxa(),
          this.getEquipamentos(),
          this.getCodigoRecurso(),
        ]);
        await this.generateXML()
        this.loading = false;
      } catch (error) {
        if (error.response) {
          const errorMessage = error.response.data;
          const regex = /<pre class="exception_value">\(\d+,\s*&#x27;(.*?)&#x27;\)<\/pre>/;
          const match = errorMessage.match(regex);

          if (match && match[1]) {
            this.$toast.error(match[1]);
          } else {
            this.$toast.error("Ocorreu um erro ao processar sua solicitação. Por favor, tente novamente mais tarde.");
          }
        } else if (error.request) {
          // A solicitação foi feita, mas não houve resposta do servidor
          console.error('No response from server:', error.request);
          this.$toast.error('Sem resposta do servidor. Verifique sua conexão com a internet.');
        } else {
          // Ocorreu um erro durante a configuração da solicitação
          console.error('Request error:', error.message);
          this.$toast.error(`Erro ao enviar a solicitação. Tente novamente mais tarde. ${error.message}`);
        }
      }
    },
    formatSequeItemGlosa(value) {
      if (value === undefined || value === null) {
        return '000'; // Retorna '000' se o valor for indefinido ou nulo
      }
      return value.toString().padStart(3, '0');
    },
    generateXML() {
      const empresa = this.empresa;
      const selectedGuides = this.selectedBudgets;
      const totalValuePerBudget = {};

      let procedimentos = [];

      for (const item of this.procedimentos.flat()) {
        if (!selectedGuides.includes(item.sessao.id)) {
          continue;
        }

        let calculoDesconto;
        let reducaoAcrescimo;

        if (item.desconto === null && item.acrescimo === null) {
          reducaoAcrescimo = '0';
        } else if (item.desconto !== null) {
          calculoDesconto = -(item.desconto);
          reducaoAcrescimo = calculoDesconto.toFixed(2);
        } else {
          reducaoAcrescimo = item.acrescimo.toFixed(2);
        }

        let valorCobranca = ((item.valor_cobranca * (reducaoAcrescimo / 100)) + item.valor_cobranca) * item.quantidade;

        if (!totalValuePerBudget[item.sessao.id]) {
          totalValuePerBudget[item.sessao.id] = [];
        }

        totalValuePerBudget[item.sessao.id].push(parseFloat(valorCobranca.toFixed(2)));
        procedimentos.push({
          'ans:sequencialItem': this.formatSequeItemGlosa(item.sequeItemGlosa),
          'ans:dataInicio': item.data_execucao_glosa ? item.data_execucao_glosa : item.data_especial,
          'ans:procRecurso': {
            'ans:codigoTabela': item.procedimento.tabela_tabela,
            'ans:codigoProcedimento': item.procedimento.codigo ? item.procedimento.codigo : 'N/a1',
            'ans:descricaoProcedimento': item.procedimento.procedimento,
          },
          'ans:codGlosaItem': item.motivoglosa.codigo ? item.motivoglosa.codigo : 'N/A2',
          'ans:valorRecursado': item.valor_glosa.toFixed(2),
          'ans:justificativaItem': cleanText(item.justificativarecursoglosa),
        })
      }

      // Adicionar Pacotes
      for (const pacote of this.pacote) {
        procedimentos.push({
          'ans:sequencialItem': this.formatSequeItemGlosa(pacote.sequeItemGlosa_pacote),
          'ans:dataInicio': pacote.data_execucao_glosa_pacote ? pacote.data_execucao_glosa_pacote : UtilsPDF.formatDateXML(pacote.data_inicio_pacote),
          'ans:procRecurso': {
            'ans:codigoTabela': pacote.pacote.tabela.tabela,
            'ans:codigoProcedimento': pacote.pacote.codigo || 'NA3',
            'ans:descricaoProcedimento': pacote.pacote.nomecomercial ? pacote.pacote.nomecomercial : pacote.pacote.nome,
          },
          'ans:codGlosaItem': pacote.motivoglosa_pacote.codigo ? pacote.motivoglosa_pacote.codigo : 'N/Apacote',
          'ans:valorRecursado': pacote.valor_glosa_pacote.toFixed(2),
          'ans:justificativaItem': cleanText(pacote.justificativarecursoglosa_pacote),
        });
      }

  // Adicionar Taxas
  for (const taxa of this.taxa) {
    procedimentos.push({
      'ans:sequencialItem': this.formatSequeItemGlosa(taxa.sequeItemGlosa_taxa),
      'ans:dataInicio': taxa.data_execucao_glosa_taxa ? taxa.data_execucao_glosa_taxa : UtilsPDF.formatDateXML(taxa.data_inicio_taxa),
      'ans:procRecurso': {
        'ans:codigoTabela': taxa.taxa.tabela.tabela,
        'ans:codigoProcedimento': taxa.taxa.codigo || 'NA4',
        'ans:descricaoProcedimento': taxa.taxa.nomecomercial ? taxa.taxa.nomecomercial : taxa.taxa.taxa.nome,
      },
      'ans:codGlosaItem': taxa.motivoglosa_taxa.codigo ? taxa.motivoglosa_taxa.codigo : 'N/A2TAxa',
      'ans:valorRecursado': taxa.valor_glosa_taxa.toFixed(2),
      'ans:justificativaItem': cleanText(taxa.justificativarecursoglosa_taxa),
    });
  }

  // Adicionar Equipamentos
  for (const equipamento of this.equipamentos) {
    procedimentos.push({
      'ans:sequencialItem':  this.formatSequeItemGlosa(equipamento.sequeItemGlosa_equipamento),
      'ans:dataInicio': equipamento.data_execucao_glosa_equipamento ? equipamento.data_execucao_glosa_equipamento : UtilsPDF.formatDateXML(equipamento.data_inicio_taxa),
      'ans:procRecurso': {
        'ans:codigoTabela': equipamento.equipamento.tabela.tabela,
        'ans:codigoProcedimento': equipamento.equipamento.codigo || "Na5",
        'ans:descricaoProcedimento': equipamento.equipamento.nomecomercial ? equipamento.equipamento.nomecomercial : equipamento.equipamento.equipamento.nome,
      },
      'ans:codGlosaItem': equipamento.motivoglosa_equipamento.codigo ? equipamento.motivoglosa_equipamento.codigo : 'N/A2equipamento',
      'ans:valorRecursado': equipamento.valor_glosa_equipamento.toFixed(2),
      'ans:justificativaItem': cleanText(equipamento.justificativarecursoglosa_equipamento),
    });
  }

      // Calcular a soma dos valores dos procedimentos
      const valorTotalGeral = procedimentos.reduce((acc, cur) => {
        return acc + parseFloat(cur['ans:valorRecursado']);
      }, 0);

      const menorNumero = Math.min(...selectedGuides);
      const dataAtual = UtilsPDF.formatDateXML(new Date());
      const dataNumerica = parseInt(dataAtual.replace(/\D/g, ''));
      const sequencialTransacao = dataNumerica + menorNumero;

      var obj = {
        'ans:mensagemTISS': {
          '@xmlns:ans': 'http://www.ans.gov.br/padroes/tiss/schemas',
          '@xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
          '@xsi:schemaLocation': 'http://www.ans.gov.br/padroes/tiss/schemas http://www.ans.gov.br/padroes/tiss/schemas/tissV4_01_00.xsd',
          'ans:cabecalho': {
            'ans:identificacaoTransacao': {
              'ans:tipoTransacao': 'RECURSO_GLOSA',
              'ans:sequencialTransacao': sequencialTransacao,
              'ans:dataRegistroTransacao': dataAtual,
              'ans:horaRegistroTransacao': moment().format('HH:mm:ss')
            } //identificacaoTransacao
            , 'ans:origem': {
              'ans:identificacaoPrestador': {
                'ans:codigoPrestadorNaOperadora': empresa.convenio_codigo ? empresa.convenio_codigo : 'Na 6'
              } //identificacaoPrestador
            } //origem
            , 'ans:destino': {
              'ans:registroANS': empresa.convenio_ans
            } //destino
            , 'ans:Padrao': empresa.versao_xml
          }, //cabecalho
          'ans:prestadorParaOperadora': {
            'ans:recursoGlosa': {
              'ans:guiaRecursoGlosa': {
                'ans:registroANS': empresa.convenio_ans,
                'ans:numeroGuiaRecGlosaPrestador': empresa.nrprotocolorecursoglosa, // J
                'ans:nomeOperadora': empresa.convenio,
                'ans:objetoRecurso': '2',
                'ans:numeroGuiaRecGlosaOperadora': empresa.nrprotocolorecursoglosa, // J
                'ans:dadosContratado': {
                  'ans:codigoPrestadorNaOperadora': empresa.convenio_codigo ?  empresa.convenio_codigo  : 'Na7'
                }, //dadosContratado
                'ans:numeroLote': (empresa.id + 1),
                'ans:numeroProtocolo': empresa.protocolo_glosa_operadora, // PEG D
                'ans:opcaoRecurso': {
                  'ans:recursoGuia': {
                    'ans:numeroGuiaOrigem': '00000001',
                    'ans:numeroGuiaOperadora': empresa.nrprotocolorecursoglosa, // J
                    'ans:opcaoRecursoGuia': {
                      'ans:itensGuia': procedimentos,
                    }, //opcaoRecursoGuia
                  }, //recursoGuia
                }, //opcaoRecurso
              'ans:valorTotalRecursado': valorTotalGeral.toFixed(2),
              'ans:dataRecurso': UtilsPDF.formatDateXML(new Date()),
              } // guiaRecursoGlosa
            } // recursoGlosa
          } //prestadorParaOperadora
        } //mensagemTISS
      }
      const root = create(obj)
      const xml = root.end({ prettyPrint: true, headless: true });

      const inicioCabecalho = xml.indexOf("<ans:cabecalho>");
      const fimCabecalho = xml.indexOf("</ans:cabecalho>");
      const cabecalho = xml.substring(inicioCabecalho, fimCabecalho + "</ans:cabecalho>".length);

      const inicioPrestador = xml.indexOf("<ans:prestadorParaOperadora>");
      const fimPrestador = xml.indexOf("</ans:prestadorParaOperadora>");
      const prestadorParaOperadora = xml.substring(inicioPrestador, fimPrestador + "</ans:prestadorParaOperadora>".length);

      const conteudoParaHash = cabecalho + prestadorParaOperadora;
      //  Retiramos as tags,  espaços, linhas em branco
      let conteudoTexto = conteudoParaHash.replace(/\n/g, '').replace(/>\s+</g, '').replace(/<[^>]+>/g, '').trim();
      // console.log(conteudoTexto)
      const hash = md5(conteudoTexto);
      var epilogo = { 'ans:epilogo': { 'ans:hash': hash } };
      obj['ans:mensagemTISS']['ans:epilogo'] = epilogo['ans:epilogo'];
      const rootWithEpilogue = create(obj)
      const xmlWithEpilogue = rootWithEpilogue.end({ prettyPrint: true, headless: true });
      const xmlWithEncoding = `<?xml version="1.0" encoding="iso-8859-1"?>\n${xmlWithEpilogue}`;
      const nomePaciente = empresa.paciente.replace(/\s/g, "_")
      const nomeConvenio = empresa.convenio.replace(/\s/g, "_")
      const now = new Date()
      this.selectedBudgets.length > 1 ?
        this.downloadXML(xmlWithEncoding, `XML_RECURSO_GLOSA_${nomeConvenio}_${this.formatDateNormal(now)}_Conta_${empresa.id}.xml`)
        : this.downloadXML(xmlWithEncoding, `XML_RECURSO_GLOSA_${nomePaciente}_${nomeConvenio}_${this.formatDateNormal(empresa.data_inicio)}_ate_${this.formatDateNormal(empresa.data_fim)}_Conta_${empresa.id}.xml`)
    },
    
    downloadXML(xml, filename) {
      try {
        // Cria um Blob com os dados XML
        const blob = new Blob([xml], { type: 'application/xml' });

        // Cria um URL para o Blob
        const url = window.URL.createObjectURL(blob);

        // Cria um elemento de link temporário
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = filename;

        // Adiciona o link ao documento e dispara o download
        document.body.appendChild(a);
        a.click();

        // Limpa e remove o link após o download
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        // Mensagem de sucesso
        this.$toast.success('O download do XML de Rescurso de Glosa foi realizado com sucesso!');
      } catch (error) {
        console.error('Erro ao salvar o arquivo:', error);
        this.$toast.error("Erro ao salvar o arquivo: " + error.message);
      }
    },
    formatDateNormal(value) {
      if (!value) return "";
      return moment(value).format("DD_MM_YYYY");
    },
    async getOrcamento() {
      if (this.selectedBudgets.length > 0) {
        try {
          const { data } = await api.get(`atendimentos/relatorio-orcamento/?orcamento=${this.selectedBudgets[0]}`);
          this.empresa = data[0];
          // console.log('Empresa --> ', this.empresa)
        } catch (error) {
          console.error('Erro ao buscar os dados da empresa:', error);
        }
      }
    },
    async getProcedimento() {
      if (this.selectedBudgets.length > 0) {
        try {
          const { data } = await api.get(`atendimentos/evolucoes_xml/?orcamento=${this.selectedBudgets[0]}`);
          // console.log(data)
          this.procedimentos = data.filter(item => item.recurso_glosa === true && item.ativo === true);
          // console.log('Procedimento --> ', this.procedimentos)
        } catch (error) {
          console.error('Erro ao buscar os dados dos procedimentos:', error);
        }
      }
    },
    async getPacote() {
      if (this.selectedBudgets.length > 0) {
        try {
          const { data } = await api.get(`atendimentos/pacotes_xml/?orcamento=${this.selectedBudgets[0]}`);
          // console.log('getPacote -', data)
          this.pacote = data.filter(item => {
            return item.recurso_glosa_pacote === true && item.ativo === true;
          });
        } catch (error) {
          this.$toast.error('Erro ao buscar os dados do Pacote (L-514).', error);
        }
      }
    },
    async getTaxa() {
      if (this.selectedBudgets.length > 0) {
        try {
          const { data } = await api.get(`atendimentos/taxas_xml/?orcamento=${this.selectedBudgets[0]}`);
          // console.log('getTaxa -', data)
          this.taxa = data.filter(item => {
            return item.recurso_glosa_taxa === true && item.ativo === true;
          });
          // console.log(this.taxa)
        } catch (error) {
          this.$toast.error('Erro ao buscar os dados da taxa (L-525).', error);
        }
      }
    },
    async getEquipamentos() {
      if (this.selectedBudgets.length > 0) {
        try {
          const { data } = await api.get(`atendimentos/equipamentos_xml/?orcamento=${this.selectedBudgets[0]}`);
          this.equipamentos = data.filter(item => {
            return item.recurso_glosa_equipamento === true && item.ativo === true;
          });
        } catch (error) {
          this.$toast.error('Erro ao buscar os dados da Equipamento (L-536).', error);
        }
      }
    },
    getMotivoGlosaNome(motivoglosaId) {
      // Verifique se this.codigorecurso é um array válido
      if (Array.isArray(this.codigorecurso) && this.codigorecurso.length > 0) {
        const motivo = this.codigorecurso.find(item => item.id === motivoglosaId);
        return motivo ? motivo.codigo : 'N/A';
      } else {
        // Caso codigorecurso esteja indefinido ou vazio
        return 'N/A';
      }
    },
    async getCodigoRecurso() {
      try {
        const { data } = await api.get('uteis/motivoglosa/');
        this.codigorecurso = data.filter(item => item.ativo);
        // console.log('Código Recurso:', this.codigorecurso); // Verifique os dados aqui
      } catch (error) {
        alert("Erro ao listar os recursos de glosa");
        console.log(error);
      }
     },
  },
};
</script>